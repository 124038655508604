import type {Tracking} from "../../models/Tracking";
import EVENT_EN from "./Events";
import EVENT_DE from "./EventsDE";
import StatisticApi from "../../api/StatisticApi";
import {fromCartRemoved, toCartAdded} from "../../reducers/cartReducer";
import { configSuccessGet, trackerLoaded } from "../../reducers/configReducer";
import {categorySet, productDetailSuccessFetched} from "../../reducers/contentReducer";
import {searchPhraseSet} from "../../reducers/filterReducer";
import { googleAnalyticsSet } from "../../reducers/consentReducer";
import { GET_CONFIG_REQUEST, UPDATE_TRACKING, updateTracker } from "../../actions/products";

export const trackers: Tracking[] = [];

export const tracker = store => next => action => {
	const EVENT = getLanguageCode(store, action).includes("de") ? EVENT_DE : EVENT_EN;

	switch (action.type) {
		case toCartAdded.type: {
			const statisticApi = new StatisticApi(getConfigId(store));
			statisticApi.trackProductAddedToCart(action.payload.id);
			break;
		}
		default:
			break;
	}

	// third party tracker
	if (store.getState().consent.googleAnalytics) {
		if (store.getState().config.trackerLoaded) {
			switch (action.type) {
				case toCartAdded.type: {
					logEvent(EVENT.CATEGORIES.BOOKMARKS, EVENT.ACTIONS.BOOKMARKS.ADD_PRODUCT, action.payload.name);
					break;
				}
				case fromCartRemoved.type: {
					logEvent(EVENT.CATEGORIES.BOOKMARKS, EVENT.ACTIONS.BOOKMARKS.REMOVE_PRODUCT, action.payload.name);
					break;
				}
				case configSuccessGet.type: {
					logEvent(EVENT.CATEGORIES.SHOP, EVENT.ACTIONS.SHOP.LOADED);
					break;
				}
				case productDetailSuccessFetched.type: {
					logEvent(EVENT.CATEGORIES.PRODUCT, EVENT.ACTIONS.PRODUCT.PRODUCT_VISITED, action.payload.product ? action.payload.product.name : null);
					break;
				}
				case searchPhraseSet.type: {
					if (action.payload) logEvent(EVENT.CATEGORIES.SEARCH, EVENT.ACTIONS.SEARCH.QUERY, action.payload);
					break;
				}
				case categorySet.type: {
					logEvent(EVENT.CATEGORIES.FILTER, EVENT.ACTIONS.FILTER.CATEGORY, action.payload ? action.payload.title : EVENT.LABEL.FILTER_RESET);
					break;
				}
				default: {
					break;
				}
			}
		} else {
			switch (action.type) {
				case configSuccessGet.type:
				case googleAnalyticsSet.type:
				case UPDATE_TRACKING:
				case GET_CONFIG_REQUEST:
				case trackerLoaded.type:
					break;
				default:
					if (store.getState().consent.googleAnalytics && !store.getState().config.trackerLoaded) {
						updateTracker(store.getState().config.trackingProperties, store.dispatch);
					}
					break;
			}
		}
	}

	return next(action);
};

const logEvent = (cat, action, label) => {
	trackers.forEach(tracker => {
		tracker.logEvent(cat, action, label);
	});
};

const getLanguageCode = (store, action) => {
	try {
		return store.getState().config.trackingProperties.trackerLanguage ||
			action.payload.configurationDetails.trackingProperties.trackerLanguage || "";
	} catch {
		return "";
	}
};

const getConfigId = store => {
	try {
		return store.getState().config.dealerId || store.getState().config.dealerDetails.cfgKey || store.getState().config.dealerDetails.identifier.dealerNo;
	} catch (e) {
		return "";
	}
};
