// @flow
import ReactGA from "react-ga4";
import type {Tracking} from "../../models/Tracking";
import BaseTracker from "./BaseTracker";

export default class GoogleTracker extends BaseTracker implements Tracking {
	constructor(trackingIds: string[], withEcommerce: boolean = false, dealerId: string = "unknown", dealerName: string = "unknown", customerNo: string = "unknown") {
		super(trackingIds[0], withEcommerce);
		const gtagOptions = {
			"dimension1": dealerId,
			"dimension2": dealerName,
			"dimension3": customerNo,
		};

		ReactGA.initialize(trackingIds
				.map(id => ({
					trackingId: id,
					gaOptions: {},
					gtagOptions
				})),
			{
				testMode: false,
			}
		);
	}

	handleGenericEvent(cat: string, action: string, label: any) {
		ReactGA.event(action, {
			category: cat,
			label: label,
			event_label: label,
			event_source: "UCL"
		});
	}

}
